<template>
  <div class="index">
    <img
      class="bg"
      src="../../assets/image/banner_baohanyanzhen@2x.png"
      alt=""
    >
    <div class="content">
      <div class="content-right">
        <div class="right-tit">
          {{ isOpen==1?'填写以下信息即可查询验证您的保函':'核验成功' }}
        </div>
        <div
          class="right-con"
          v-if="isOpen==1"
        >
          <a-form-model
            ref="form1"
            :model="applicantInform"
            :rules="rule1"
            :label-col="{xs:{span:12},sm:{span:4},}"
            :wrapper-col="{xs:{span:24},sm:{span:12},}"
          >
            <a-form-model-item
              label="保函编号"
              prop="guaranteeNumber"
            >
              <a-input v-model="applicantInform.guaranteeNumber" />
            </a-form-model-item>
            <!-- <a-form-model-item
              label="企业名称"
              prop="customerName"
            >
              <a-input v-model="applicantInform.customerName" />
            </a-form-model-item>
            <a-form-model-item
              label="验证码"
              prop="code"
            >
              <div style="display:flex;">
                <a-input v-model="applicantInform.code" />
                <img
                  :src="codeImg"
                  alt=""
                  style="height: 35px;margin-left: 53px;"
                  @click="getImgcode"
                >
              </div>
            </a-form-model-item> -->
            <div class="nextBtn">
              <!-- <a-button
                  type="primary"
                  @click="handleReset"
                >
                  重置
                </a-button> -->
              <div
                class="reset"
                @click="handleReset"
              >
                重置
              </div>
              <!-- <a-button
                  type="primary"
                  @click="handleSubmit"
                >
                  提交
                </a-button> -->
              <div
                class="submit"
                @click="handleSubmit"
              >
                提交
              </div>
            </div>
          </a-form-model>
        </div>
        <div
          class="right-con"
          v-if="isOpen==2"
        >
          <img
            src="../../assets/image/heyan.png"
            alt=""
          >
          <div class="con-text">
            核验成功
          </div>
          <div style="background: #fafafa;position: relative;">
            <a-form-model
              ref="form1"
              :model="form"
              :rules="rule1"
              :label-col="{xs:{span:12},sm:{span:6},}"
              :wrapper-col="{xs:{span:24},sm:{span:12},}"
            >
              <a-form-model-item label="保函编号">
                <div>{{ form.guaranteeNumber }}</div>
              </a-form-model-item>
              <a-form-model-item label="被保证人">
                <div>{{ form.businessOrgName }}</div>
              </a-form-model-item>
              <a-form-model-item label="保函金额">
                <div>{{ throundsHandle(form.amount) }}元</div>
              </a-form-model-item>
              <a-form-model-item label="保函有效期">
                <div>{{ form.validityPeriod }}</div>
              </a-form-model-item>
            </a-form-model>
            <div class="bg-box" />
          </div>
        </div>
      </div>
    </div>
    <a-modal
      :visible="visible"
      title="验证结果"
      @cancel="visible = false"
      footer
      centered
      width="600"
    >
      <div class="modal">
        <img
          v-if="iconImg"
          src="../../assets/image/icon_chengggong@2x.png"
          alt=""
        >
        <img
          v-else
          src="../../assets/image/icon_shibai@2x.png"
          alt=""
        >
        <div class="title">
          核验成功
        </div>
        <ul>
          <li><span class="label">保函编号</span><span class="value">{{ form.orderId }}</span></li>
          <li><span class="label">被保证人</span><span class="value">{{ form.name }}</span></li>
          <li><span class="label">保函金额</span><span class="value">{{ throundsHandle(form.sumAmount) }}元</span></li>
          <li><span class="label">保函有效期</span><span class="value">{{ form.validityPeriod }}</span></li>
          <li><span class="label">保函状态</span><span class="value">{{ insureStatus(form.stauts) }}</span></li>
        </ul>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { graphicsCode, smsCode } from '@/api/code.js';
import { insureVerification } from '@/api/guaranteeTruth.js';
import { dictDetail } from '@/api/performance.js';
export default {
  data() {
    // const compareToFirstPhone = (rule, value, callback) => {
    //   const pattern = /^1[3|4|5|7|8|9][0-9]{9}$/;
    //   if (pattern.test(value)) {
    //     callback();
    //   } else if (!value) {
    //     callback('请输入手机号');
    //   } else {
    //     callback('手机号格式不正确');
    //   }
    // };
    return {
      iconImg: false,
      visible: false,
      isOpen: 1,
      codeImg: '',
      isTime: true,
      applicantInform: {
        customerName: '',
        guaranteeNumber: '',
        code: '',
      },
      form: {
        guaranteeNumber: '',
        businessOrgName: '',
        amount: '',
        guaranteeStatusDescribe: '',
        guaranteeStatus: '',
      },
      rule1: {
        guaranteeNumber: [{ required: true, message: '请输入保函编号', trigger: 'blur' }],
        customerName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      dictStatus: null,
    };
  },
  mounted() {
    this.getImgcode();
  },
  methods: {
    throundsHandle (value) {
      if (value) {
        let a = value.toString().split('.')[0]
        let b = value.toString().split('.')[1]
        return a.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + ( b ? '.' + b : '')
      } else {
        return value
      }
    },
    getImgcode() {
      dictDetail({ dictName: 'insureStatus' }).then(res => {
        this.dictStatus = res.data.data;
      });
      graphicsCode({ source: 'ACC_USER_LOGIN' }).then(res => {
        this.codeImg = window.URL.createObjectURL(res.data);
      });
    },
    insureStatus(status) {
      if (this.dictStatus) {
        for (const i in this.dictStatus) {
          if (this.dictStatus[i].value === status) {
            return this.dictStatus[i].label;
          }
        }
      } else {
        return '';
      }
    },
    getCode() {
      this.timer = '';
      if (this.applicantInform.code !== '' && this.applicantInform.responsiblePersonPhone !== '' && !!this.applicantInform.code) {
        smsCode({ source: 'ACC_USER_LOGIN', checkCode: this.applicantInform.code, phoneNum: this.applicantInform.responsiblePersonPhone }).then(res => {
          this.isCode = res.data;
          if (res.data.status === 'FAIL') {
            this.$message.warning(this.isCode.errorMsg);
          } else {
            this.isTime = false;
            this.timer = setInterval(this.countDown, 1000);
          }
        });
      } else {
        this.$message.warning('手机号码和图形验证码不为空');
      }
    },
    countDown() {
      if (this.time > 0) {
        this.time--;
      } else {
        this.time = 10;
        this.isTime = true;
        clearInterval(this.timer);
      }
    },
    handleReset() {
      this.$refs.form1.resetFields();
    },
    handleSubmit() {
      this.$refs.form1.validate(valid => {
        if (valid) {
          insureVerification({ ...this.applicantInform }).then(res => {
            if (res.data.status === 500) {
              this.$notification.error({ message: res.data.message });
            } else {
              this.iconImg = true;
              this.visible = true;
              this.form = res.data.data;
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.index{
  display: flex;
  flex-direction: column;
  background-color: #F2F3F4;
  .bg {
    width: 100%;
    height: 200px;
  }
}
.content {
  width: 1180px;
  height: 600px;
  margin: 30px auto 40px;
  background: #ffff;
}
.content-right {
  padding: 0 50px;
  .right-tit {
    padding: 30px 0 15px;
    border-bottom: 1px solid #ececec;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .right-tips {
    color: cornflowerblue;
    margin: 20px 0;
  }
  .right-con {
    margin: 100px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .con-text{
      font-size: 20px;
      margin: 20px 0;
    }
  }
}
/deep/ .nextBtn {
  margin-top: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // ::v-deep .ant-form-item-control {
  //   left: 45%;
  // }
  // ::v-deep .ant-btn:nth-of-type(1) {
  //   margin-left: 0px!important;
  // }
  // ::v-deep .ant-btn {
  //   width: 120px;
  //   height: 40px;
  //   margin-left: 30px;
  // }
  .reset {
    width: 160px;
    height: 54px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 27px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    text-align: center;
    line-height: 54px;
    margin-right: 30px;
    cursor: pointer;
  }
  .submit {
    width: 160px;
    height: 54px;
    background: linear-gradient(90deg, #BE8E51, #DBBD8E);
    border-radius: 27px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    line-height: 54px;
    cursor: pointer;
  }
}
.bg-box{
    position: absolute;
    left: 0;
    top: 0;
    width: 120px;
    height: 256px;
    background: #f2f2f2!important;
}
::v-deep .ant-form{
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .ant-form-item{
  width:500px;
  z-index: 10;
}
::v-deep .ant-form-item-label {
  white-space: normal;
}
::v-deep .ant-steps-item-container {
  height: 120px;
}
::v-deep .ant-input,.ant-select,
.ant-select-selection {
  width: 325px;
}
::v-deep .ant-modal-close-x {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .ant-col-sm-4 {
  width: 25%;
}
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  img {
    width: 60px;
    height: 60px;
  }
  .title {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin: 20px 0 40px;
  }
  ul {
    border: 1px solid #E5E5E5;
    border-bottom: none;
    li {
      display: flex;
      border-bottom: 1px solid #E5E5E5;
      .label {
        width: 140px;
        height: 40px;
        background: #F9F9F9;
        line-height: 40px;
        text-align: right;
        padding-right: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        border-right: 1px solid #E5E5E5;
      }
      .value {
        width: 400px;
        height: 40px;
        background: #FFFFFF;
        line-height: 40px;
        padding-left: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>
